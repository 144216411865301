<template>
  <div>
    <b-tabs>
      <b-tab
        title="Tipo contrato"
        active
      >
        <b-col cols="12">
          <good-table
            :columns="columns"
            :rows="rows"
            :select-row="true"
            title-table="Tipo de contratos"
            :is_active_create="$can('create', 'tipocontrato')"
            :is_active_edit="$can('update', 'tipocontrato')"
            :is_active_trash="$can('delete', 'tipocontrato')"
            :is_active_trash_all="$can('delete', 'tipocontrato')"
            :is-loading="isLoading"
            @Crear="Crear"
            @Edit_one="Edit_one"
            @edit="Edit"
            @delete-register="deleteRegister"
            @delete-all="deleteAll"
            @active-register="activeRegister"
            @selection-changed="selectionChanged"
          />
        </b-col>
      </b-tab>
      <b-tab
        title="Papelera"
      >
        <b-col cols="12">
          <good-table
            :columns="columnsDelete"
            :rows="rowsDelete"
            :select-row="true"
            title-table="Tipo de contratos"
            :is_active_trash_back="$can('delete', 'tipocontrato')"
            :is_active_trash_all_back="$can('delete', 'tipocontrato')"
            @delete-register-back="deleteRegisterBack"
            @delete-all-back="deleteAllBack"
            @selection-changed="selectionChangedDelete"
          />
        </b-col>
      </b-tab>
    </b-tabs>
    <vue-form
      :modal-show="modalShow"
      :info-data="infoData"
      @create-result="getContract"
      @edit-result="getContract"
      @close="close"
    />
  </div>
</template>

<script>
import {
  BCol, BTabs, BTab,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/components/typeContractCrud/form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCol,
    GoodTable,
    BTabs,
    BTab,
    'vue-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      rowsDelete: [],
      selectRow: null,
      selecction: [],
      selecctionDelete: [],
      modalShow: false,
      infoData: {},
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'ID',
          field: 'id',
          width: '100px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Nombre',
          field: 'name',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Codigo',
          field: 'pais_code',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Configuración',
          field: 'config.name',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Descripción',
          field: 'description',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Descripción',
          },
        },
        {
          label: 'Activo',
          field: 'is_active',
          width: '230px',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            customFilter: false,
            placeholder: 'Selecciona un estado',
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              { value: '0', text: 'Desactivado' },
              { value: '1', text: 'Activo' },
            ],
          },
        },
        {
          label: 'Contrato a imprimir',
          field: 'contract_version.name',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Creado',
          field: 'created_at',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          width: '230px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
      ],
      columnsDelete: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Descripción',
          field: 'description',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Descripción',
          },
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Borrado',
          field: 'deleted_at',
          formatFn: this.formatFn,
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
      ],
    }
  },
  created() {
    this.getContract()
    this.getContractDelete()
  },
  methods: {
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    close() {
      this.modalShow = false
      this.infoData = {}
    },
    getContract() {
      this.isLoading = true
      this.$http.get('/v1/type-contract/')
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    getContractDelete() {
      this.$http.get('/v1/type-contract/delete')
        .then(res => { this.rowsDelete = res.data })
    },
    activeRegister(val) {
      this.$http.put(`/v1/type-contract/update/active/${val.id}`, {
        active: !val.is_active,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El tipo de contrato se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.getContract()
        this.getContractDelete()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    Crear() {
      this.modalShow = true
    },
    Edit(val) {
      this.infoData = val
      this.modalShow = true
    },
    Edit_one() {
      if (this.selecction.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      }
      this.infoData = this.selecction['0']
      this.modalShow = true
    },
    deleteRegister(val) {
      this.$http.delete(`/v1/type-contract/delete/${val.id}`).then(res => {//eslint-disable-line
        this.getContract()
        this.getContractDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/type-contract/delete/', {
          id_contracts: this.selecction,
        }).then(res => {//eslint-disable-line
          this.getContract()
          this.getContractDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
    selectionChangedDelete(val) {
      this.selecctionDelete = val.selectedRows
    },
    deleteRegisterBack(val) {
      this.$http.delete(`/v1/type-contract/delete/restore/${val.id}`).then(res => {//eslint-disable-line
        this.getContract()
        this.getContractDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha Restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha Restaurado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAllBack() {
      if (this.selecctionDelete.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/type-contract/delete/restoreall', {
          id_contracts: this.selecctionDelete,
        }).then(res => {//eslint-disable-line
          this.getContract()
          this.getContractDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registro seleccionado se han restaurado correctamente.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
  },
}
</script>
