<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypeContractForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Nombre"
                    label-for="typecontract-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="typecontract-name"
                        v-model="name"
                        placeholder="Escribe el nombre del tipo de contrato"
                        :state="errors.length > 0 ? false:null"
                        name="typecontract-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Descripción"
                    label-for="typecontract-descripcion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Descripción"
                    >
                      <b-form-input
                        id="typecontract-descripcion"
                        v-model="description"
                        placeholder="Escribe una descripción"
                        :state="errors.length > 0 ? false:null"
                        name="typecontract-descripcion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Codigo de contrato"
                    label-for="country-pais-code"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="alpha"
                      name="Codigo de contrato"
                    >
                      <b-form-input
                        id="country-pais-code"
                        v-model="pais_code"
                        placeholder="Codigo de contrato"
                        :state="errors.length > 0 ? false:null"
                        name="country-pais-code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Numeración de contrato"
                    label-for="country-config-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules=""
                      name="Numeración de contrato"
                    >
                      <v-select
                        v-model="config_id"
                        name="country-config-id"
                        label="name"
                        :options="rowsConfig"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Contrato a imprimir"
                    label-for="country-config-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules=""
                      name="Contrato a imprimir"
                    >
                      <v-select
                        v-model="contract_versions_id"
                        name="country-config-id"
                        label="name"
                        :options="rowsContractVersion"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      id: null,
      pais_code: null,
      config_id: null,
      contract_versions_id: null,
      value: [],
      options: [],
      permissionsByRole: null,
      rowsConfig: null,
      rowsContractVersion: null,
      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar tipo de contrato' : 'Crear tipo de contrato'
    },
  },
  watch: {
    infoData(val) {
      this.clear()
      this.name = val.name
      this.description = val.description
      this.pais_code = val.pais_code
      this.id = val.id
      this.setConfig(val.config_id)
      this.setContactVersion(val.contract_versions_id)
    },
  },
  created() {
    this.getConfig()
    this.getContractVersion()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clear() {
      this.name = null
      this.description = null
      this.pais_code = null
      this.config_id = null
      this.contract_versions_id = null
    },
    getConfig() {
      this.$http.get('/v1/config-contract/get/')
        .then(res => {
          this.rowsConfig = res.data
        })
    },
    getContractVersion() {
      this.$http.get('/api/v1/contract-version')
        .then(res => {
          this.rowsContractVersion = res.data
        })
    },
    setConfig(value) {
      if (value) {
        this.config_id = this.rowsConfig.filter(row => row.id === value)
      }
    },
    setContactVersion(value) {
      if (value) {
        this.contract_versions_id = this.rowsContractVersion.filter(row => row.id === value)
      }
    },
    save() {
      this.$refs.TypeContractForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            this.$http.put(`/v1/type-contract/update/${this.id}`, {
              name: this.name,
              description: this.description,
              pais_code: this.pais_code,
              config_id: this.config_id ? this.config_id.id : this.config_id,
              contract_versions_id: this.contract_versions_id ? this.contract_versions_id.id : this.contract_versions_id,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El tipo de contrato se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            this.$http.post('/v1/type-contract/create', {
              name: this.name,
              description: this.description,
              is_active: true,
              pais_code: this.pais_code,
              config_id: this.config_id ? this.config_id.id : this.config_id,
              contract_versions_id: this.contract_versions_id ? this.contract_versions_id.id : this.contract_versions_id,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El tipo de contrato se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
