<template>
  <div>
    <vue-type-contract />
  </div>
</template>
<script>
import TypeContract from '@/components/typeContractCrud/typeContract.vue'

export default {
  components: {
    'vue-type-contract': TypeContract,
  },
}
</script>
