var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":"Login","no-close-on-esc":"","no-close-on-backdrop":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"TypeContractForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"typecontract-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typecontract-name","placeholder":"Escribe el nombre del tipo de contrato","state":errors.length > 0 ? false:null,"name":"typecontract-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Descripción","label-for":"typecontract-descripcion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typecontract-descripcion","placeholder":"Escribe una descripción","state":errors.length > 0 ? false:null,"name":"typecontract-descripcion"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Codigo de contrato","label-for":"country-pais-code"}},[_c('validation-provider',{attrs:{"rules":"alpha","name":"Codigo de contrato"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country-pais-code","placeholder":"Codigo de contrato","state":errors.length > 0 ? false:null,"name":"country-pais-code"},model:{value:(_vm.pais_code),callback:function ($$v) {_vm.pais_code=$$v},expression:"pais_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Numeración de contrato","label-for":"country-config-id"}},[_c('validation-provider',{attrs:{"rules":"","name":"Numeración de contrato"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"country-config-id","label":"name","options":_vm.rowsConfig,"placeholder":"Seleccione una opción"},model:{value:(_vm.config_id),callback:function ($$v) {_vm.config_id=$$v},expression:"config_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Contrato a imprimir","label-for":"country-config-id"}},[_c('validation-provider',{attrs:{"rules":"","name":"Contrato a imprimir"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"country-config-id","label":"name","options":_vm.rowsContractVersion,"placeholder":"Seleccione una opción"},model:{value:(_vm.contract_versions_id),callback:function ($$v) {_vm.contract_versions_id=$$v},expression:"contract_versions_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }